import {put} from "redux-saga/effects";
import {createNotification, NOTIFICATION_TYPE} from "../actions/uiActions";

export function* handleResponseNotification(data, title, failOnly, errorActions) {
    if (data.success === 1) {
        if (!failOnly)
            yield put(createNotification(title, data.message, NOTIFICATION_TYPE.SUCCESS));
    } else {
        let action = null;
        if (errorActions && 'error' in data && data.error in errorActions)
            action = errorActions[data.error];

        yield put(createNotification(title, data.message, NOTIFICATION_TYPE.ERROR, action));
    }
}
