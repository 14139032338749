import {
    EXPEDIENTE_ADD_GRUPO,
    EXPEDIENTE_RESET,
    EXPEDIENTE_SET_ATENDENTE,
    SET_FORA_EXPEDIENTE
} from '../actions/actionTypes';
import {updateObject} from "../../utils";


const initialState = {
    grupos: {},
    atendente: null,
    foraExpediente:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case EXPEDIENTE_ADD_GRUPO:
            return updateObject(state, {grupos: {...state.grupos, [action.idGrupo]: action.payload}});
        case EXPEDIENTE_SET_ATENDENTE:
            return updateObject(state, {atendente: action.payload});
        case SET_FORA_EXPEDIENTE:
            return updateObject(state, {foraExpediente: action.status});
        case EXPEDIENTE_RESET:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
