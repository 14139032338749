import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import createSagaMiddleware from 'redux-saga';
import {Provider} from "react-redux";
import authReducer from "./store/reducers/authReducer";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import wsReducer from "./store/reducers/wsReducer";
import {onWSConnect} from "./store/sagas/wsSaga";
import queuesReducer from "./store/reducers/queuesReducer";
import uiReducer from "./store/reducers/uiReducer";
import expedienteReducer from "./store/reducers/expedienteReducer";
import atendimentoReducer from "./store/reducers/atendimentoReducer";
import {onOpenAtendimento} from "./store/sagas/atendimentoSaga";
import shortcutsReducer from "./store/reducers/shortcutsReducer";
import {uiSaga} from "./store/sagas/uiSaga";
import persistState from 'redux-localstorage';
import {authSaga} from './store/sagas/authSaga';
import {expedienteSaga} from './store/sagas/expedienteSaga';
// noinspection ES6UnusedImports
import {updateObject} from "./utils";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {CaptureConsole} from '@sentry/integrations';


Sentry.init({
    dsn: "https://2caf8aa7436146e28119c8806a8c9ca8@o1153403.ingest.sentry.io/6232459",
    integrations: [new BrowserTracing(), new CaptureConsole()],
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    environment: process.env.NODE_ENV || "production"
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const composeEnhancers = /*process.env.NODE_ENV === 'development' &&*/ window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
    auth: authReducer,
    ws: wsReducer,
    queues: queuesReducer,
    ui: uiReducer,
    expediente: expedienteReducer,
    atendimento: atendimentoReducer,
    shortcuts: shortcutsReducer
});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware), sentryReduxEnhancer, persistState('s3nd_atendente', {
    slicer: () => {
        return (state) => {
            let subset = [];

            for (const stateKey in state) {
                if (['ws'].findIndex((v) => v === stateKey) !== -1) continue;
                subset[stateKey] = state[stateKey];
            }

            return updateObject(
                subset, 
                {
                    ui: updateObject(subset.ui, {modal: null, modalOpen: false}),
                    expediente: updateObject(subset.expediente, {foraExpediente: false}),
                }
            );
        }
    }
})));

sagaMiddleware.run(onOpenAtendimento);
sagaMiddleware.run(onWSConnect);
sagaMiddleware.run(uiSaga);
sagaMiddleware.run(authSaga);
sagaMiddleware.run(expedienteSaga);

ReactDOM.render(
    <React.StrictMode thirdParty={false}>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorkerRegistration.register();
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

