import {WS_CONNECTED, WS_CONNECTING, WS_DISCONNECTED, WS_RESET} from '../actions/actionTypes';
import {updateObject} from "../../utils";

export const NOT_CONNECTED = 'NOT_CONNECTED';
export const CONNECTING = 'CONNECTING';
export const CONNECTED = 'CONNECTED';

const initialState = {
    status: NOT_CONNECTED,
    authenticated: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case WS_CONNECTING:
            return updateObject(state, {status: CONNECTING, authenticated: false});
        case WS_CONNECTED:
            return updateObject(state, {status: CONNECTED, authenticated: true});
        case WS_DISCONNECTED:
            return updateObject(state, {status: NOT_CONNECTED, authenticated: false});
        case WS_RESET:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
