import {
    QUEUE_OPERATOR_UPDATE,
    QUEUE_PRIMARY_UPDATE,
    QUEUE_SECONDARY_UPDATE,
    QUEUES_RESET
} from '../actions/actionTypes';
import {updateObject} from "../../utils";

const initialState = {
    primaryQueue: [],
    secondaryQueue: [],
    operatorQueue: [],
    primaryLoading: true,
    secondaryLoading: true,
    operatorLoading: true
};

const removeDuplicates = (queue) => {
    return queue.filter(function (item, pos, self) {
        return self.findIndex((t) => t.idAtendimento === item.idAtendimento) === pos;
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case QUEUE_PRIMARY_UPDATE:
            return updateObject(state, {primaryQueue: removeDuplicates(action.payload), primaryLoading: false});
        case QUEUE_SECONDARY_UPDATE:
            return updateObject(state, {secondaryQueue: removeDuplicates(action.payload), secondaryLoading: false});
        case QUEUE_OPERATOR_UPDATE:
            return updateObject(state, {operatorQueue: removeDuplicates(action.payload), operatorLoading: false});
        case QUEUES_RESET:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
