import {
    UI_CLEAR_CURRENT_PANEL,
    UI_CLOSE_MAIN_CONTENT,
    UI_CLOSE_MODAL,
    UI_CLOSE_RIGHT_PANEL,
    UI_OPEN_MODAL,
    UI_RESET,
    UI_SET_LEFT_PANEL,
    UI_SET_MAIN_CONTENT,
    UI_SET_RIGHT_PANEL
} from '../actions/actionTypes';
import {updateObject} from "../../utils";

const initialState = {
    leftPanel: null,
    rightPanel: null,
    currentPanel: null,
    lastCurrentPanel: null,
    mainContent: null,
    modal: null,
    modalOpen: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UI_SET_LEFT_PANEL:
            return updateObject(state, {
                leftPanel: action.payload,
                currentPanel: 'left',
                lastCurrentPanel: state.currentPanel
            });
        case UI_SET_RIGHT_PANEL:
            return updateObject(state, {
                rightPanel: action.payload,
                currentPanel: 'right',
                lastCurrentPanel: state.currentPanel
            });
        case UI_CLOSE_RIGHT_PANEL:
            return updateObject(state, {rightPanel: null, currentPanel: null, lastCurrentPanel: state.currentPanel});
        case UI_CLEAR_CURRENT_PANEL:
            return updateObject(state, {lastCurrentPanel: state.currentPanel, currentPanel: null});
        case UI_CLOSE_MAIN_CONTENT:
            return updateObject(state, {mainContent: null});
        case UI_SET_MAIN_CONTENT:
            return updateObject(state, {mainContent: action.payload});
        case UI_OPEN_MODAL:
            return updateObject(state, {modal: action.modal, modalOpen: true});
        case UI_CLOSE_MODAL:
            return updateObject(state, {modalOpen: false});
        case UI_RESET:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
