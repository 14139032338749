import axios from "axios";
import {createNotification, NOTIFICATION_TYPE} from "../store/actions/uiActions";
import {put} from "redux-saga/effects";
import {setTag} from "@sentry/react";

export class NotificationCenterService {
    static* subscribe(authToken, notificationToken) {
        try {
            const r = yield axios.post('subscribe', {token: notificationToken}, {
                baseURL: process.env.REACT_APP_NOTIFICATION_CENTER_URL,
                headers: {
                    'Authorization': 'Bearer ' + authToken
                }
            });

            if (r.data.success !== 1) {
                yield put(createNotification("Notificações", "Não foi possível configurar o envio de notificações nesse dispositivo!", NOTIFICATION_TYPE.ERROR));
                setTag("notifications", "error");
            }
        } catch (e) {
            console.error("NotificationCenter: Erro ao fazer subscribe", e);
            setTag("notifications", "error");
        }
    }

    static* unsubscribe(authToken, notificationToken) {
        try {
            const r = yield axios.post('unsubscribe', {token: notificationToken}, {
                baseURL: process.env.REACT_APP_NOTIFICATION_CENTER_URL,
                headers: {
                    'Authorization': 'Bearer ' + authToken
                }
            });

            if (r.data.success !== 1) {
                yield put(createNotification("Notificações", "Não foi possível remover o envio de notificações deste dispositivo!", NOTIFICATION_TYPE.ERROR));
            }
        } catch (e) {
            console.error("NotificationCenter: Erro ao fazer unsubscribe", e);
        }

    }
}
