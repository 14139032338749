import styles from './Loading.module.scss';
import cx from 'classnames';
import {useEffect} from "react";
import * as PropTypes from "prop-types";

function timeoutAction(timeout) {
    console.warn("Um componente estorou o limite de " + timeout + " para exibir o conteudo...");
}

export function LoadingIndicator(props) {
    return <img className={cx(styles.loading)} style={props.style || {}} src="imgs/load.svg" alt=""/>;
}

LoadingIndicator.propTypes = {style: PropTypes.object};

function Loading({children, loading, style, timeout}) {

    useEffect(() => {
        if (!loading || !timeout) return;

        const t = setTimeout(() => {
            timeoutAction(timeout);
        }, timeout);

        return () => {
            clearTimeout(t);
        };
    }, [loading, timeout]);

    if (loading) {
        return <LoadingIndicator style={style}/>;
    }

    return children;
}

export default Loading;
