/***** WS ******/
export const WS_CONNECTING = 'WS_CONNECTING';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const WS_MESSAGE = 'WS_MESSAGE';
export const WS_AUTHENTICATED = 'WS_AUTHENTICATED';
export const WS_RESET = 'WS_RESET';

/***** Auth *****/
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_NOTIFICATION_TOKEN = 'SET_NOTIFICATION_TOKEN';

/***** UI ******/
export const UI_SET_LEFT_PANEL = 'UI_SET_LEFT_PANEL';
export const UI_SET_RIGHT_PANEL = 'UI_SET_RIGHT_PANEL';
export const UI_CLOSE_RIGHT_PANEL = 'UI_CLOSE_RIGHT_PANEL';
export const UI_CLEAR_CURRENT_PANEL = 'UI_CLEAR_CURRENT_PANEL';
export const UI_CLOSE_MAIN_CONTENT = 'UI_CLOSE_MAIN_CONTENT';
export const UI_SET_MAIN_CONTENT = 'UI_SET_MAIN_CONTENT';
export const UI_OPEN_MODAL = 'UI_OPEN_MODAL';
export const UI_CLOSE_MODAL = 'UI_CLOSE_MODAL';
export const UI_CREATE_NOTIFICATION = 'UI_CREATE_NOTIFICATION';
export const UI_PLAY_NOTIFICATION_SOUND = 'UI_PLAY_NOTIFICATION_SOUND';
export const UI_RESET = 'UI_RESET';

/***** Queue *****/
export const QUEUE_PRIMARY_UPDATE = 'QUEUE_PRIMARY_UPDATE';
export const QUEUE_SECONDARY_UPDATE = 'QUEUE_SECONDARY_UPDATE';
export const QUEUE_OPERATOR_UPDATE = 'QUEUE_OPERATOR_UPDATE';
export const QUEUES_RESET = 'QUEUES_RESET';

/***** Expediente *****/
export const EXPEDIENTE_ADD_GRUPO = 'EXPEDIENTE_ADD_GRUPO';
export const EXPEDIENTE_SET_ATENDENTE = 'EXPEDIENTE_SET_ATENDENTE';
export const SET_FORA_EXPEDIENTE = 'SET_FORA_EXPEDIENTE';
export const GET_EXPEDIENTE = 'GET_EXPEDIENTE';
export const EXPEDIENTE_RESET = 'EXPEDIENTE_RESET';


/***** Atendimento *****/
export const ATENDIMENTO_OPEN = 'ATENDIMENTO_OPEN';
export const ATENDIMENTO_OPENED = 'ATENDIMENTO_OPENED';
export const ATENDIMENTO_CLOSE = 'ATENDIMENTO_CLOSE';
export const ATENDIMENTO_LOAD = 'ATENDIMENTO_LOAD';
export const ATENDIMENTO_LOAD_MESSAGES = 'ATENDIMENTO_LOAD_MESSAGES';
export const ATENDIMENTO_LOAD_TRANSFERENCES = 'ATENDIMENTO_LOAD_TRANSFERENCES';
export const ATENDIMENTO_NEW_MESSAGE = 'ATENDIMENTO_NEW_MESSAGE';
export const ATENDIMENTO_SEND_MESSAGE = 'ATENDIMENTO_SEND_MESSAGE';
export const ATENDIMENTO_LOAD_ANNOTATIONS_CONFIG = 'ATENDIMENTO_LOAD_ANNOTATIONS_CONFIG';
export const ATENDIMENTO_LOAD_ANNOTATIONS = 'ATENDIMENTO_LOAD_ANNOTATIONS';
export const ATENDIMENTO_UPDATE_ANNOTATION = 'ATENDIMENTO_UPDATE_ANNOTATION';
export const ATENDIMENTO_ANNOTATIONS_SAVED = 'ATENDIMENTO_ANNOTATIONS_SAVED';
export const ATENDIMENTO_LOAD_TRANSFERENCE_OPTIONS = 'ATENDIMENTO_LOAD_TRANSFERENCE_OPTIONS';
export const ATENDIMENTO_TRANSFER = 'ATENDIMENTO_TRANSFER';
export const ATENDIMENTO_FINALIZE = 'ATENDIMENTO_FINALIZE';
export const ATENDIMENTO_START = "ATENDIMENTO_START";
export const ATENDIMENTO_START_LOAD = "ATENDIMENTO_START_LOAD";
export const ATENDIMENTO_TAG = "ATENDIMENTO_TAG";

/***** Shortcuts *****/
export const SHORTCUTS_SET_GRUPO = 'SHORTCUTS_ADD_GRUPO';
export const SHORTCUTS_RESET = 'SHORTCUTS_RESET';
