import {
    ATENDIMENTO_ANNOTATIONS_SAVED,
    ATENDIMENTO_CLOSE,
    ATENDIMENTO_FINALIZE,
    ATENDIMENTO_LOAD,
    ATENDIMENTO_LOAD_ANNOTATIONS,
    ATENDIMENTO_LOAD_ANNOTATIONS_CONFIG,
    ATENDIMENTO_LOAD_MESSAGES,
    ATENDIMENTO_LOAD_TRANSFERENCE_OPTIONS,
    ATENDIMENTO_LOAD_TRANSFERENCES,
    ATENDIMENTO_NEW_MESSAGE,
    ATENDIMENTO_OPEN,
    ATENDIMENTO_OPENED,
    ATENDIMENTO_SEND_MESSAGE,
    ATENDIMENTO_TRANSFER,
    ATENDIMENTO_UPDATE_ANNOTATION,
    ATENDIMENTO_START,
    ATENDIMENTO_START_LOAD,
    ATENDIMENTO_TAG
} from "./actionTypes";

export const openAtendimento = (atendimento) => ({type: ATENDIMENTO_OPEN, payload: atendimento});
export const openedAtendimento = (atendimento) => ({type: ATENDIMENTO_OPENED, payload: atendimento});
export const loadAtendimento = (idAtendimento, payload) => ({type: ATENDIMENTO_LOAD, idAtendimento, payload});
export const closeAtendimento = () => ({type: ATENDIMENTO_CLOSE});
export const loadMessages = (messages) => ({type: ATENDIMENTO_LOAD_MESSAGES, payload: messages});
export const loadTransferences = (transferences) => ({type: ATENDIMENTO_LOAD_TRANSFERENCES, payload: transferences});
export const sendMessage = (idAtendimento, text, attachment) => ({
    type: ATENDIMENTO_SEND_MESSAGE,
    payload: {idAtendimento, text, attachment}
});
export const newMessage = (payload) => ({type: ATENDIMENTO_NEW_MESSAGE, payload});

export const loadAnnotations = (idAtendimento, payload) => ({
    type: ATENDIMENTO_LOAD_ANNOTATIONS,
    idAtendimento,
    payload
});
export const loadAnnotationsConfig = (idAtendimento, payload) => ({
    type: ATENDIMENTO_LOAD_ANNOTATIONS_CONFIG,
    idAtendimento,
    payload
});
export const updateAnnotation = (key, value) => ({type: ATENDIMENTO_UPDATE_ANNOTATION, key, value});
export const annotationsSaved = () => ({type: ATENDIMENTO_ANNOTATIONS_SAVED});

export const loadTransferenceOptions = (payload) => ({type: ATENDIMENTO_LOAD_TRANSFERENCE_OPTIONS, payload});
export const transferAtendimento = (idAtendimento, target) => ({type: ATENDIMENTO_TRANSFER, idAtendimento, target});

export const finalizeAtendimento = () => ({type: ATENDIMENTO_FINALIZE});

export const iniciarAtendimento = (idAtendimento,idAtendente) => ({type: ATENDIMENTO_START,idAtendimento,idAtendente});
export const startLoading = (loadingStart) => ({type: ATENDIMENTO_START_LOAD,loadingStart});
export const tagSaved = (idAtendimento,marcador) => ({type:ATENDIMENTO_TAG,idAtendimento,marcador});
