import styles from './Login.module.scss';
import cx from 'classnames';
import {connect} from "react-redux";
import logo from '../../assets/img/logo.png';
import {auth} from '../../store/actions';

function Login({loading,auth}) {

    const onSubmit = (ev) =>{
        ev.preventDefault();

        auth(ev.target.user.value,ev.target.password.value);
    }

    return (
        <div className={cx(styles.container)}>
            <div className={cx(styles.login)}>
                <div className={cx(styles.header)}>
                    <img className={cx(styles.header__logo)} src={logo} width={40} height={52} alt="S3ND"/>
                    <h4 className={cx(styles.title)}>Bem-vindo de volta! <br/> <small>Faça seu login agora. Há muitas
                        novidades esperando por você.</small></h4>
                </div>
                <form className={cx(styles.login_form)} onSubmit={onSubmit}>
                    <div>
                        <label className={cx(styles.login_label)} htmlFor="#user">Usuário:</label><br/>
                        <input className={cx(styles.login_input)} id="user"  name="user" type="text" />
                    </div>
                    <div>
                        <label className={cx(styles.login_label)} htmlFor="#password">Senha:</label><br/>
                        <input className={cx(styles.login_input)}  id="password"  name="password" type="password"  />
                    </div>
                    <div style={{display:'flex'}} >
                        <button className={cx(styles.login__primary_btn)} type='submit' >
                            {loading === true ? <div className={cx(styles.ldsellipsis)}>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                            </div> : "Entrar"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading
    };
}

const mapDispatchToProps = (dispatch) => {
    return { 
        auth: (email, password) => dispatch(auth(email, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
