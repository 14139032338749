import {initializeApp} from "firebase/app";
import {getMessaging, isSupported} from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBuXyoIbbkWpXjBRlaRV6HV7DNjqWCZivA",
    authDomain: "s3nd-atendente.firebaseapp.com",
    projectId: "s3nd-atendente",
    storageBucket: "s3nd-atendente.appspot.com",
    messagingSenderId: "748509145569",
    appId: "1:748509145569:web:73c34a6bba8aa1d01a8aa0"
};

export const firebaseApp = initializeApp(firebaseConfig);

let messaging;
isSupported().then(r => {
    if (!r) return;
    messaging = getMessaging(firebaseApp);
})

export {messaging};
