import {
    EXPEDIENTE_ADD_GRUPO,
    EXPEDIENTE_RESET,
    EXPEDIENTE_SET_ATENDENTE,
    GET_EXPEDIENTE,
    SET_FORA_EXPEDIENTE
} from "./actionTypes";

export const addExpedienteGrupo = (idGrupo, expedientes) => ({
    type: EXPEDIENTE_ADD_GRUPO,
    idGrupo: idGrupo,
    payload: expedientes
});

export const setExpedienteAtendente = (expedientes) => ({
    type: EXPEDIENTE_SET_ATENDENTE,
    payload: expedientes
});

export const setForaExpediente = (status) => ({
    type: SET_FORA_EXPEDIENTE,
    status: status
});

export const getExpediente = () => ({type: GET_EXPEDIENTE});

export const resetExpediente = () => ({type: EXPEDIENTE_RESET});
