import {fork, put, select, take, takeEvery} from 'redux-saga/effects';
import {AUTH_INITIATE_LOGOUT, AUTH_USER, EXPEDIENTE_SET_ATENDENTE} from "../actions/actionTypes";
import axios from "axios";
import {handleResponseNotification} from "./utils";
import {authFail, authStart, authSuccess, logoutSucceed} from "../actions/authActions";
import {connect, disconnect} from '../../websocket';
import {getAuthToken} from "./atendimentoSaga";
import {resetShortcuts, setShortcuts} from "../actions/shortcutsActions";
import {groupBy} from "../../utils";
import {NotificationCenterService} from "../../service/notificationCenterService";
import {getAuth} from "./wsSaga";
import {configureScope} from "@sentry/react";
import {isExpediente} from "./expedienteSaga";
import {resetExpediente} from "../actions/expedienteActions";
import {closeAtendimento} from "../actions/atendimentoActions";
import {resetQueues} from "../actions/queuesActions";
import {resetUI} from "../actions/uiActions";
import {resetWS} from "../actions";

function* loadShortcuts(token) {

    try {
        const response = yield axios.get('/api/chat/get_atalhos', {
            baseURL: process.env.REACT_APP_S3ND_BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + token || (yield select(getAuthToken)),
            }
        });

        const groups = groupBy(response.data.atalhos, 'id_grupo_atendente');
        for (const id in groups) {
            yield put(setShortcuts(+id, groups[id]));
        }
    } catch (e) {
        console.error(e);
    }
}


function* auth(action) {
    try {

        yield put(authStart())

        const formData = new FormData();

        formData.append('email', action.email);
        formData.append('senha', action.password);

        const response = yield axios.post('api/chat/login', formData, {
            baseURL: process.env.REACT_APP_S3ND_BASE_URL
        });

        if (response.data.success === 1) {
            yield put(authSuccess(response.data.token, parseInt(response.data.userId), response.data.userName));
            connect();
            yield fork(loadShortcuts, response.data.token);
            yield take(EXPEDIENTE_SET_ATENDENTE);
            yield fork(isExpediente);
        } else {
            yield put(authFail(response.data.message));
        }

        yield fork(handleResponseNotification, response.data, "Acesso ao sistema.", true);
    } catch (e) {
        console.error(e);
    }
}

function* logoutInit() {
    const auth = yield select(getAuth);
    if (auth.notificationToken) {
        try {
            yield fork(NotificationCenterService.unsubscribe, auth.token, auth.notificationToken);
            disconnect();
        } catch (e) {
            console.error("Erro na comunicação com o NotificationCenter", e);
        }
    }
    yield put(logoutSucceed());
    yield put(resetExpediente());
    yield put(closeAtendimento());
    yield put(resetQueues());
    yield put(resetShortcuts());
    yield put(resetUI());
    yield put(resetWS());
    configureScope(scope => scope.setUser(null));
}

export function* authSaga() {
    yield takeEvery(AUTH_USER, auth);
    yield takeEvery(AUTH_INITIATE_LOGOUT, logoutInit);
}
