import {takeEvery} from 'redux-saga/effects';
import {UI_CREATE_NOTIFICATION, UI_PLAY_NOTIFICATION_SOUND} from "../actions/actionTypes";
import {NotificationManager} from 'react-notifications';
import {NOTIFICATION_TYPE} from "../actions/uiActions";

const DEFAULT_DURATION = 5000;
export const getAuth = (state) => state.auth;

function createNotification(action) {
    switch (action.payload.type) {
        case NOTIFICATION_TYPE.ERROR:
            NotificationManager.error(action.payload.text, action.payload.title, DEFAULT_DURATION, action.payload.onClick);
            break;
        case NOTIFICATION_TYPE.SUCCESS:
            NotificationManager.success(action.payload.text, action.payload.title, DEFAULT_DURATION, action.payload.onClick);
            break;
        default:
            NotificationManager.info(action.payload.text, action.payload.title, DEFAULT_DURATION, action.payload.onClick);
    }
}

function playAudio(action) {
    const audio = new Audio(action.payload === 'atendimento' ? 'audio/new_chat.mp3' : 'audio/new_msg_chat.mp3');
    const promise = audio.play();

    if (promise !== undefined) {
        promise.then(() => {
        }).catch(console.error);
    }
}

export function* uiSaga() {
    yield takeEvery(UI_CREATE_NOTIFICATION, createNotification);
    yield takeEvery(UI_PLAY_NOTIFICATION_SOUND, playAudio);
}
