import {DateTime} from "luxon";

export const updateObject = (oldObject, newProperties) => ({...oldObject, ...newProperties});

export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export const calcEsperaTotalAtendimento = (dataTransbordo) => {
    const date = DateTime.now();

    const atDate = DateTime.fromSQL(dataTransbordo) || DateTime.now();

    if (atDate.invalid) {
        return "--:--";
    }

    let elapsed = (date.toMillis() - atDate.toMillis()) / 1000;

    // const seconds = Math.floor(elapsed % 60);
    elapsed = Math.floor(elapsed / 60);

    const minutes = elapsed % 60;
    elapsed = Math.floor(elapsed / 60);

    const hours = elapsed;

    return (hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0'));
};

export const calcEsperaEmExpedienteAtendimento = (dataTransbordo, expediente) => {
    const date = DateTime.now();

    function getDataInicioEFim(dataRef) {
        const dayOfWeek = dataRef.weekday % 7;
        const expDia = expediente.find((e) => +e.dia === dayOfWeek);

        if (expDia === undefined) return {fimExp: null, inicioExp: null};

        const [horasEntrada, minutosEntrada] = expDia.entrada.split(':');
        const [horasSaida, minutosSaida] = expDia.saida.split(':');

        const inicioExp = dataRef.set({hours: horasEntrada, minutes: minutosEntrada, seconds: 0});
        const fimExp = dataRef.set({hours: horasSaida, minutes: minutosSaida, seconds: 0});

        return {fimExp, inicioExp};
    }

    let atDate = DateTime.fromSQL(dataTransbordo) || DateTime.now();
    let tempoEmExpediente = 0;

    if (atDate.invalid) {
        return "--:--";
    }

    function increaseDay() {
        atDate = atDate.plus({day: 1}).set({hours: 0, minutes: 0, seconds: 0});
    }

    while (!atDate.hasSame(date, 'day')) {
        const {fimExp, inicioExp} = getDataInicioEFim(atDate);
        if (fimExp === null) {
            // Pula o dia quando não tem expediente
            increaseDay();
            continue;
        }

        const dataInicio = Math.max(atDate, inicioExp);
        tempoEmExpediente += fimExp - dataInicio;
        increaseDay();
    }
    // Se é hoje
    const {fimExp, inicioExp} = getDataInicioEFim(date);

    if (fimExp !== null) {
        const dataInicio = Math.max(atDate.toMillis(), inicioExp.toMillis());
        const dataFim = Math.min(fimExp.toMillis(), date.toMillis());
        tempoEmExpediente += dataFim - dataInicio;
    }

    tempoEmExpediente /= 1000;

    // const seconds = Math.floor(tempoEmExpediente % 60);
    tempoEmExpediente = Math.floor(tempoEmExpediente / 60);

    const minutes = tempoEmExpediente % 60;
    tempoEmExpediente = Math.floor(tempoEmExpediente / 60);

    const hours = tempoEmExpediente;

    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
};

export function formatDate(date) {
    return `${date.day.toString().padStart(2, '0')}/${date.month.toString().padStart(2, '0')}/${date.year} às ${date.hour.toString().padStart(2, '0')}:${date.minute.toString().padStart(2, '0')}`;
}

export function formatSToMMSS(sec) {
    let elapsed = sec;
    const seconds = Math.floor(elapsed % 60);
    elapsed = Math.floor(elapsed / 60);

    const minutes = elapsed;

    return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
}
