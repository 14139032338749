import {
    AUTH_FAIL,
    AUTH_LOGOUT,
    AUTH_START,
    AUTH_SUCCESS,
    SET_AUTH_REDIRECT_PATH,
    SET_NOTIFICATION_TOKEN
} from '../actions/actionTypes';
import {updateObject} from "../../utils";

const initialState = {
    token: "",
    userName: "",
    userId: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    notificationToken: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_START:
            return updateObject(state, {error: null, loading: true});
        case AUTH_SUCCESS:
            return updateObject(state, {
                token: action.token,
                userId: action.userId,
                error: null,
                userName: action.userName,
                loading: false
            });
        case AUTH_FAIL:
            return updateObject(state, {error: action.error, loading: false});
        case AUTH_LOGOUT:
            return initialState;
        case SET_AUTH_REDIRECT_PATH:
            return updateObject(state, {authRedirectPath: action.path});
        case SET_NOTIFICATION_TOKEN:
            return updateObject(state, {notificationToken: action.payload});
        default:
            return state;
    }
};

export default reducer;
