import {connect} from "react-redux";
import Login from "./containers/Login/Login";
import {NotificationContainer} from "react-notifications";
import {getExpediente} from './store/actions/expedienteActions'
import {lazy, Suspense, useEffect} from "react";
import {LoadingIndicator} from "./hoa/Loading";

function App({auth, foraExpediente, getExpediente}) {
    const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));
    const ForaExpediente = lazy(() => import("./containers/ForaExpediente/ForaExpediente"));

    useEffect(() => {
        getExpediente();
    }, []);

    if (!auth.token) return (<><Login/><NotificationContainer/></>)

    if (foraExpediente === true) return (
        <Suspense fallback={<LoadingIndicator/>}>
            <ForaExpediente/>
        </Suspense>);

    return (
        <Suspense fallback={<LoadingIndicator/>}>
            <AuthenticatedApp/>
        </Suspense>);
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        foraExpediente: state.expediente.foraExpediente
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getExpediente: () => dispatch(getExpediente())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
