import {
    UI_CLEAR_CURRENT_PANEL,
    UI_CLOSE_MAIN_CONTENT,
    UI_CLOSE_MODAL,
    UI_CLOSE_RIGHT_PANEL,
    UI_CREATE_NOTIFICATION,
    UI_OPEN_MODAL,
    UI_PLAY_NOTIFICATION_SOUND,
    UI_RESET,
    UI_SET_LEFT_PANEL,
    UI_SET_MAIN_CONTENT,
    UI_SET_RIGHT_PANEL
} from "./actionTypes";

export const NOTIFICATION_TYPE = {ERROR: 'ERROR', WARN: 'WARN', INFO: 'INFO', SUCCESS: 'SUCCESS'};

export const setLeftPanel = (panel) => ({type: UI_SET_LEFT_PANEL, payload: panel});
export const setRightPanel = (panel) => ({type: UI_SET_RIGHT_PANEL, payload: panel});
export const setMainContent = (panel) => ({type: UI_SET_MAIN_CONTENT, payload: panel});
export const closeMainContent = () => ({type: UI_CLOSE_MAIN_CONTENT});
export const closeCurrentPanel = () => ({type: UI_CLEAR_CURRENT_PANEL});
export const closeRightPanel = () => ({type: UI_CLOSE_RIGHT_PANEL});
export const openModal = (modal, additionalPayload) => ({type: UI_OPEN_MODAL, payload: additionalPayload, modal});
export const closeModal = () => ({type: UI_CLOSE_MODAL});
export const resetUI = () => ({type: UI_RESET});

export const createNotification = (title, text, type, onClick) => ({
    type: UI_CREATE_NOTIFICATION,
    payload: {title, text, type, onClick}
});

export const playNotificationSound = (type) => ({type: UI_PLAY_NOTIFICATION_SOUND, payload: type});
