import {eventChannel} from 'redux-saga';

export function onMessageReduxHandler() {
    return eventChannel(emitter => {
        try {
            const channel4Broadcast = new BroadcastChannel('channel4');

            channel4Broadcast.onmessage = (event) => {
                if (!event.data) return;

                if (!['openAtendimento'].includes(event.data.type)) return;
                emitter(event.data);
            };

            return () => {
                channel4Broadcast.close();
            };
        } catch (e) {
            console.error(e);
            return () => {
            };
        }
        }
    )
}
