import {SHORTCUTS_RESET, SHORTCUTS_SET_GRUPO} from '../actions/actionTypes';
import {updateObject} from "../../utils";

const initialState = {
    loadingShortcuts: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHORTCUTS_SET_GRUPO:
            return updateObject(state, {loadingShortcuts: false, [action.idGrupo]: action.payload});
        case SHORTCUTS_RESET:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
