import {call, debounce, fork, put, select, takeEvery} from 'redux-saga/effects';
import {
    ATENDIMENTO_CLOSE,
    ATENDIMENTO_FINALIZE,
    ATENDIMENTO_OPEN,
    ATENDIMENTO_OPENED,
    ATENDIMENTO_SEND_MESSAGE,
    ATENDIMENTO_START,
    ATENDIMENTO_TAG,
    ATENDIMENTO_TRANSFER,
    ATENDIMENTO_UPDATE_ANNOTATION
} from "../actions/actionTypes";
import {closeMainContent, closeRightPanel, setMainContent, setRightPanel} from "../actions/uiActions";
import {sendWS} from "../../websocket";
import axios from "axios";
import {
    annotationsSaved,
    closeAtendimento,
    loadAnnotationsConfig,
    loadAtendimento,
    loadTransferenceOptions,
    openedAtendimento,
    startLoading
} from "../actions/atendimentoActions";
import {store} from "../../index";
import {handleResponseNotification} from "./utils";

export const getAuthToken = (state) => state.auth.token;
export const getAtendimento = (state) => state.atendimento;

function* handleOpenedAtendimento(action) {
    sendWS({"type": "setAtendimento", "payload": action.payload.idAtendimento});
    sendWS({"type": "openAtendimento", "payload": {"idAtendimento": action.payload.idAtendimento}});
    yield put(setMainContent('atendimento'));
    yield loadTransferenceOptionsAtendimento();
    yield loadAnnotationsConfigAtendimento(action.payload.idAtendimento);
}

function* handleCloseAtendimento() {
    sendWS({"type": "setAtendimento", "payload": null});
    yield put(closeMainContent());
}

function* loadTransferenceOptionsAtendimento() {
    try {
        const response = yield axios.get('/api/chat/get_opcoes_transferencia', {
            baseURL: process.env.REACT_APP_S3ND_BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        const t = response.data.opcoes.map((f) => ({label: f.title, value: f.type + '_' + f.id}));

        yield fork(handleResponseNotification, response.data, "Transferência", true);
        yield put(loadTransferenceOptions(t));
    } catch (e) {
        console.error(e);
    }
}

function* loadAnnotationsConfigAtendimento(idAtendimento) {
    try {
        const response = yield axios.get('/api/chat/get_anotacoes_config?id_atendimento=' + idAtendimento, {
            baseURL: process.env.REACT_APP_S3ND_BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        yield fork(handleResponseNotification, response.data, "Anotações", true);
        yield put(loadAnnotationsConfig(parseInt(response.data.id_atendimento), response.data.config));
    } catch (e) {
        console.error(e);
    }
}

function* sendMessage(action) {
    try {
        if (action.payload.text) {
            const response = yield axios.post('/api/chat/atendimento/send_msg', JSON.stringify({
                'id_atendimento': action.payload.idAtendimento,
                'mensagem': action.payload.text
            }), {
                baseURL: process.env.REACT_APP_S3ND_BASE_URL,
                headers: {
                    'Authorization': 'Bearer ' + (yield select(getAuthToken)),
                }
            });

            yield fork(handleResponseNotification, response.data, "Envio de Mensagem", true);
            yield put(closeRightPanel());
            
        } else if (action.payload.attachment) {
            const formData = new FormData();

            formData.append('anexo', action.payload.attachment);
            formData.append('id_atendimento', action.payload.idAtendimento)

            const response = yield axios.post('/api/chat/atendimento/send_anexo', formData, {
                baseURL: process.env.REACT_APP_S3ND_BASE_URL,
                headers: {
                    'Authorization': 'Bearer ' + (yield select(getAuthToken)),
                }
            });

            yield fork(handleResponseNotification, response.data, "Anexo");
        }

    } catch (e) {
        console.error(e);
    }
}

function* transfer(action) {
    const [type, value] = action.target.split('_');

    try {
        const formData = new FormData();

        formData.append('id_atendimento', action.idAtendimento);
        formData.append('destino', (type === 'atendente' ? 'at' : 'grupo') + "_" + value);

        //TODO: Tratar retorno
        const response = yield axios.post('/api/chat/atendimento/transfer', formData, {
            baseURL: process.env.REACT_APP_S3ND_BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });


        yield fork(handleResponseNotification, response.data, "Transferência");

        return yield put(closeAtendimento());
    } catch (e) {
        console.error(e);
    }
}

function* sendAnnotations() {

    const atendimento = yield select(getAtendimento);
    if (!atendimento.id || atendimento.isAnnotationSaved) return;

    const annotations = atendimento.annotations;

    try {
        const formData = new FormData();

        formData.append('id_atendimento', atendimento.id);

        for (const annotationsKey in annotations) {
            formData.append(annotationsKey, annotations[annotationsKey]);
        }

        //TODO: Tratar retorno
        const response = yield axios.post('/api/chat/atendimento/anotacao', formData, {
            baseURL: process.env.REACT_APP_S3ND_BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        yield fork(handleResponseNotification, response.data, "Anotações", true);

        return yield put(annotationsSaved());
    } catch (e) {
        console.error(e);
    }

}

function* finalize() {
    try {
        const atendimento = yield select(getAtendimento);
        if (!atendimento.id) return;

        yield call(sendAnnotations);

        const response = yield axios.post('/api/chat/atendimento/finalizar?id_atendimento=' + atendimento.id, null, {
            baseURL: process.env.REACT_APP_S3ND_BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        const data = response.data;

        yield fork(handleResponseNotification, response.data, "Finalizar Atendimento", false, {
            anotacao: () => store.dispatch(setRightPanel('annotations'))
        });

        if (data.success === 1)
            return yield put(closeAtendimento());
    } catch (e) {
        console.error(e);
    }
}

function* handleOpenAtendimento(action) {
    yield fork(sendAnnotations);
    yield put(openedAtendimento(action.payload));
}

function* startAtendimento(action) {

    yield put(startLoading(true));

    try {
        
        const response = yield axios.get('api/chat/atendimento/start?id_atendimento='+action.idAtendimento, {
            baseURL: process.env.REACT_APP_S3ND_BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.data.success === 1) yield put(loadAtendimento(parseInt(action.idAtendente), {idAtendente: action.idAtendente}));
        
        yield fork(handleResponseNotification, response.data, "Iniciar meu atendimento", true);
    } catch (e) {
        console.error(e);
    }

    yield put(startLoading(false));
}

function* setTag(action) {

    try {

        const formData = new FormData();

        formData.append('id_atendimento', action.idAtendimento);
        formData.append('marcador', action.marcador);

        const response = yield axios.post('api/chat/atendimento/marcador', formData ,{
            baseURL: process.env.REACT_APP_S3ND_BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
        
        yield fork(handleResponseNotification, response.data, "Marcador", true);
    } catch (e) {
        console.error(e);
    }
}

// Allows concurrent fetches of user
export function* onOpenAtendimento() {
    yield takeEvery(ATENDIMENTO_OPEN, handleOpenAtendimento);
    yield takeEvery(ATENDIMENTO_OPENED, handleOpenedAtendimento);
    yield takeEvery(ATENDIMENTO_CLOSE, handleCloseAtendimento);
    yield takeEvery(ATENDIMENTO_SEND_MESSAGE, sendMessage);
    yield takeEvery(ATENDIMENTO_TRANSFER, transfer);
    yield debounce(1000, ATENDIMENTO_UPDATE_ANNOTATION, sendAnnotations);
    yield takeEvery(ATENDIMENTO_FINALIZE, finalize);
    yield takeEvery(ATENDIMENTO_START, startAtendimento);
    yield takeEvery(ATENDIMENTO_TAG, setTag);
}
