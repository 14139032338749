import {call, delay, fork, put, select, take, takeEvery} from 'redux-saga/effects';
import {WS_AUTHENTICATED, WS_CONNECTED, WS_MESSAGE} from "../actions/actionTypes";
import {sendWS} from "../../websocket";
import {wsAuthenticated} from "../actions";
import {updateOperatorQueue, updatePrimaryQueue, updateSecondaryQueue} from "../actions/queuesActions";
import {addExpedienteGrupo, setExpedienteAtendente} from "../actions/expedienteActions";
import {groupBy} from "../../utils";
import {
    closeAtendimento,
    loadAnnotations,
    loadAtendimento,
    loadMessages,
    loadTransferences,
    newMessage,
    openAtendimento as openAtendimentoAction,
    openedAtendimento
} from "../actions/atendimentoActions";
import {getToken, isSupported} from "firebase/messaging";
import {messaging} from "../../FCM";
import {NotificationCenterService} from "../../service/notificationCenterService";
import {setNotificationToken} from "../actions/authActions";
import axios from "axios";
import {getAuthToken} from "./atendimentoSaga";
import {onMessageReduxHandler} from "../../messageHandler";
import {CONNECTED} from "../reducers/wsReducer";
import {playNotificationSound} from "../actions/uiActions";
import {setTag, setUser} from "@sentry/react";

export const getAuth = (state) => state.auth;
export const getIdAtendimento = (state) => state.atendimento.id;
export const getAtendimento = (state) => state.atendimento;
export const getQueues = (state) => state.queues;
export const getWS = (state) => state.ws;

function* authenticate() {
    const auth = yield select(getAuth);
    sendWS({"type": "authenticate", "payload": {"token": auth.token}});

    setUser({id: auth.userId});
}

function* handleMessage(action) {
    const msg = action.payload;
    const idAtendimento = yield select(getIdAtendimento);

    switch (msg.type) {
        case 'authenticated':
            return yield put(wsAuthenticated());
        case 'listaAtendimentoGrupo':
            return yield put(updatePrimaryQueue(msg.payload));
        case 'listaAtendimentoGrupoSecundario':
            return yield put(updateSecondaryQueue(msg.payload));
        case 'listaAtendimentoAtendendo':
            return yield put(updateOperatorQueue(msg.payload));
        case 'expedienteGrupos':
            const groups = groupBy(msg.payload, 'idGrupo');
            for (const id in groups) {
                yield put(addExpedienteGrupo(+id, groups[id]));
            }
            return;
        case 'clearAtendimento':
            return yield put(closeAtendimento());
        case 'loadMensagens': {
            const ev = action.payload;
            if (idAtendimento !== ev.payload.idAtendimento) {
                console.warn("Recebeu mensagens de outro atendimento.");
                return;
            }
            return yield put(loadMessages(ev.payload.mensagens));
        }
        case 'loadTransferencias': {
            const ev = action.payload;
            if (idAtendimento !== ev.payload.idAtendimento) {
                console.warn("Recebeu transferencias de outro atendimento.");
                return;
            }
            return yield put(loadTransferences(ev.payload.transferencias));
        }
        case 'newMensagem':
            yield put(newMessage(action.payload.payload));

            if (action.payload.payload.origem === "cliente")
                return yield put(playNotificationSound('message'));
            break;
        case "notificationMensagem":
            break;
        case "notificationAtendimento":
            return yield put(playNotificationSound('atendimento'));
        case 'loadAtendimento':
            if (idAtendimento !== msg.payload.id) {
                console.warn("Recebeu mensagens de outro atendimento.");
                return;
            }
            yield put(loadAnnotations(parseInt(msg.payload.id), msg.payload.informacao));
            yield put(loadAtendimento(parseInt(msg.payload.id), {idAtendente: msg.payload.id_atendente}));
            break;
        default:
            console.warn(`Mensagem não mapeada. Type: ${msg.type}`, msg);
    }
}


function* openAtendimento() {
    const atendimento = yield select(getAtendimento);

    if (!atendimento.id) return;

    const at = {
        idAtendimento: atendimento.id,
        nome: atendimento.name,
        canal: atendimento.channel,
        idGrupoAtendente: atendimento.idGrupo,
        idAtendente: atendimento.idAtendente,
        messages: atendimento.messages,
        marcador: atendimento.marcador
    };

    yield put(openedAtendimento(at));
}

function* initiateNotification() {
    if (!("Notification" in window)) {
        setTag("notifications", "unavailable");
        console.log("Este navegador não suporta notificações!");
        return;
    }

    if (!(yield isSupported()) || Notification.permission === 'denied') {
        setTag("notifications", Notification.permission === 'denied' ? 'denied' : "unsupported");
        console.log("Navegador não suporta notificações ou permissão foi recusada!");
        return;
    }

    const auth = yield select(getAuth);

    let token;
    try {
        token = yield getToken(messaging, {
            vapidKey: 'BCqcFUPmONJqi8I4fez9DdJTN9ICbn_HIO1yMtKoTmvuywnXOLfu01ooSSPwNQymtRG0lrMe_ZIZA9B4xW5b1YU'
        });
    } catch (e) {
        setTag("notifications", "error");
        console.error(e);
        return;
    }

    try {
        yield fork(NotificationCenterService.subscribe, auth.token, token);
        setTag("notifications", "ok");
    } catch (e) {
        setTag("notifications", "error");
        console.error("Erro na comunicação com o NotificationCenter", e);
    }
    yield put(setNotificationToken(token));
}

function* updateExpediente() {
    try {
        const response = yield axios.get('/api/chat/get_expediente', {
            baseURL: process.env.REACT_APP_S3ND_BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        yield put(setExpedienteAtendente(response.data.expediente));
        yield call([localStorage, localStorage.setItem], 'expediente', JSON.stringify(response.data.expediente));

        const expedienteBroadcast = new BroadcastChannel('expediente');
        expedienteBroadcast.postMessage(response.data.expediente);
    } catch (e) {
        console.error(e);
    }
}

export function* onWSConnect() {
    yield takeEvery(WS_CONNECTED, authenticate);

    yield takeEvery(WS_MESSAGE, handleMessage);

    yield takeEvery(WS_AUTHENTICATED, updateExpediente);
    yield takeEvery(WS_AUTHENTICATED, openAtendimento);
    yield takeEvery(WS_AUTHENTICATED, initiateNotification);

    const messages = yield call(onMessageReduxHandler);

    while (true) {
        const payload = yield take(messages);

        if (payload.type === "openAtendimento") {
            const ws = yield select(getWS);

            while (ws.status !== CONNECTED && ws.authenticated) {
                yield delay(500);
            }

            const queues = yield select(getQueues);

            const predicate = x => x.idAtendimento === payload.idAtendimento;
            const at = [
                ...queues.primaryQueue.filter(predicate).map(x => ({...x, queue: 'primary'})),
                ...queues.secondaryQueue.filter(predicate).map(x => ({...x, queue: 'secondary'})),
                ...queues.operatorQueue.filter(predicate).map(x => ({...x, queue: 'operator'}))
            ][0] || null;

            if (!at) continue;

            if (at.queue === "operator") {
                at.idAtendente = (yield select(getAuth)).userId;
            }

            yield put(openAtendimentoAction(at));
        }

    }
}
