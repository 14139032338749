import {call, delay, put, select, take} from 'redux-saga/effects';
import {WS_CONNECTED} from "../actions/actionTypes";
import {setForaExpediente} from '../actions/expedienteActions';
import {DateTime} from "luxon";
import {createNotification, NOTIFICATION_TYPE} from "../actions/uiActions";

export const getExpedienteAtendente = (state) => state.expediente.atendente;

function formatDate(now, expediente) {
    const [hours, minutes, seconds] = expediente.split(':');
    return now.set({hours, minutes, seconds});
}

export function* isExpediente() {
    try {
        const now = DateTime.now();
        const expediente = yield select(getExpedienteAtendente);

        if (!expediente || !expediente.hasOwnProperty(now.weekday % 7)) {
            yield put(setForaExpediente(false));
            return;
        }

        const expedienteNow = expediente[now.weekday % 7];

        const horaEntrada = formatDate(now, expedienteNow.hora_entrada + ':00');
        const horaSaida = formatDate(now, expedienteNow.hora_saida + ':59');

        let foraExpediente = false;
        if (now <= horaEntrada || now >= horaSaida) {
            foraExpediente = true;
        }

        //if(yield select(...) !== foraExpediente)
        yield put(setForaExpediente(foraExpediente));
    } catch (e) {
        console.error("isExpediente", e);
        yield put(setForaExpediente(false));
        yield put(createNotification('Expediente', "Houve um erro na validação do seu expediente!", NOTIFICATION_TYPE.ERROR));
    }

}

export function* expedienteSaga() {
    yield take(WS_CONNECTED);

    while (true) {
        yield delay(5000);
        yield call(isExpediente);
    }
}
