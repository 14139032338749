import {
    ATENDIMENTO_ANNOTATIONS_SAVED,
    ATENDIMENTO_CLOSE,
    ATENDIMENTO_LOAD,
    ATENDIMENTO_LOAD_ANNOTATIONS,
    ATENDIMENTO_LOAD_ANNOTATIONS_CONFIG,
    ATENDIMENTO_LOAD_MESSAGES,
    ATENDIMENTO_LOAD_TRANSFERENCE_OPTIONS,
    ATENDIMENTO_LOAD_TRANSFERENCES,
    ATENDIMENTO_NEW_MESSAGE,
    ATENDIMENTO_OPENED,
    ATENDIMENTO_UPDATE_ANNOTATION,
    ATENDIMENTO_START_LOAD
} from '../actions/actionTypes';
import {updateObject} from "../../utils";

const initialState = {
    id: null,
    idGrupo: null,
    idAtendimento: null,
    name: null,
    channel: null,
    messages: [],
    loadingMessages: true,
    transferences: [],
    loadingTransferences: true,
    transferenceOptions: [],
    annotationsConfig: [],
    annotations: null,
    isAnnotationSaved: true,
    loadingTransferenceOptions: true,
    loadingAnnotations: true,
    loadingAnnotationsConfig: true,
    loadingStart:false,
    marcador:null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ATENDIMENTO_OPENED:
            const atendimento = action.payload;

            return updateObject(initialState, {
                id: atendimento.idAtendimento,
                name: atendimento.nome,
                channel: atendimento.canal,
                idGrupo: atendimento.idGrupoAtendente,
                idAtendente: atendimento.idAtendente,
                messages: [],
                marcador:atendimento.marcador
            });
        case ATENDIMENTO_CLOSE:
            return initialState;
        case ATENDIMENTO_LOAD_MESSAGES:
            return updateObject(state, {messages: action.payload, loadingMessages: false});
        case ATENDIMENTO_LOAD_TRANSFERENCES:
            return updateObject(state, {transferences: action.payload, loadingTransferences: false});
        case ATENDIMENTO_NEW_MESSAGE:
            const msg = action.payload;
            if (msg.idAtendimento !== state.id) return state;

            const messages = [...state.messages, msg];
            messages.sort((a, b) => a.id && a.id - b.id);

            return updateObject(state, {messages: messages});
        case ATENDIMENTO_LOAD_TRANSFERENCE_OPTIONS:
            return updateObject(state, {transferenceOptions: action.payload, loadingTransferenceOptions: false});
        case ATENDIMENTO_LOAD_ANNOTATIONS_CONFIG:
            if (action.idAtendimento !== state.id) return state;

            return updateObject(state, {annotationsConfig: action.payload, loadingAnnotationsConfig: false});
        case ATENDIMENTO_LOAD_ANNOTATIONS:
            if (action.idAtendimento !== state.id) return state;

            return updateObject(state, {
                annotations: action.payload,
                loadingAnnotations: false,
                isAnnotationSaved: true
            });
        case ATENDIMENTO_UPDATE_ANNOTATION:
            return updateObject(state, {
                annotations: updateObject(state.annotations, {[action.key]: action.value}),
                isAnnotationSaved: false
            });
        case ATENDIMENTO_ANNOTATIONS_SAVED:
            return updateObject(state, {isAnnotationSaved: true});
        case ATENDIMENTO_LOAD:
            return updateObject(state, {idAtendente: action.payload.idAtendente});
        case ATENDIMENTO_START_LOAD:
            return updateObject(state, {loadingStart: action.loadingStart});
        default:
            return state;
    }
}

export default reducer;
